<template>
  <div class="login">
    <div class="login-wrap" v-if="type === 'login'">
      <el-form ref="loginRefs" :model="form" :rules="rulesLogin">
        <div style="display: flex; justify-content: space-between;">
          <div class="login-title">欢迎登录弹幕库</div>
          <div>
            <el-button type="text" @click="type = 'register'">注册账号</el-button>
          </div>
        </div>
        <el-form-item prop="account">
          <el-input
            v-model="form.account"
            style="width: 100%;"
            prefix-icon="el-icon-user"
            auto-complete="off"
            placeholder="请输入账号"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            style="width: 100%;"
            v-model="form.password"
            type="password"
            prefix-icon="el-icon-lock"
            auto-complete="off"
            placeholder="请输入密码"
            show-password
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-input
            v-model="form.code"
            prefix-icon="el-icon-search"
            auto-complete="off"
            style="width: 63%"
            placeholder="请输入验证码"
          >
          </el-input>
          <div class="code">
            <img :src="captchaSrc" @click="getCaptcha" class="code-image"/>
          </div>
        </el-form-item>

        <el-button
          type="primary"
          style="width: 100%"
          :loading="loading"
          @click="onLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form>
    </div>
    <div class="register-wrap" v-if="type === 'register'">
      <el-form ref="registerRefs" :model="form" :rules="rulesRegister">
        <div style="display: flex; justify-content: space-between;">
          <div class="login-title">欢迎注册弹幕库</div>
          <div>
            <el-button type="text" @click="type = 'login'">返回登录</el-button>
          </div>
        </div>
        <el-form-item prop="account">
          <el-input
            v-model="form.account"
            style="width: 100%;"
            prefix-icon="el-icon-user"
            auto-complete="off"
            placeholder="请输入创建的账号"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            style="width: 100%;"
            v-model="form.password"
            type="password"
            prefix-icon="el-icon-lock"
            auto-complete="off"
            placeholder="请输入6-20位数字+字母或符号组合"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password_confirm">
          <el-input
            style="width: 100%;"
            v-model="form.password_confirm"
            type="password"
            prefix-icon="el-icon-lock"
            auto-complete="off"
            placeholder="请再次输入密码"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="form.code"
            prefix-icon="el-icon-search"
            auto-complete="off"
            style="width: 63%"
            placeholder="请输入验证码"
          >
          </el-input>
          <div class="code">
            <img :src="captchaSrc" @click="getCaptcha" class="code-image"/>
          </div>
        </el-form-item>

        <el-button
          type="primary"
          style="width: 100%"
          :loading="loading"
          @click="onRegister"
        >
          <span v-if="!loading">注 册</span>
          <span v-else>注 册 中...</span>
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {apiGetCaptcha, apiRegister} from "@/api/passport";

import config from "@/config";

export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    };
    return {
      type: "login",
      captchaSrc: "",
      form: {
        account: "",
        password: "",
        password_confirm: "",
        code: "",
        hash: "",
      },
      rulesLogin: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
      },
      rulesRegister: {
        account: [
          {
            required: true,
            message: '请输入创建的账号',
            trigger: ['change', 'blur']
          },
          {
            min: 3,
            max: 12,
            message: '账号长度应为5-12',
            trigger: ['change', 'blur']
          }
        ],
        password: [
          {
            required: true,
            message: '请输入6-20位密码',
            trigger: ['change', 'blur']
          },
          {
            min: 6,
            max: 20,
            message: '密码长度应为6-20',
            trigger: ['change', 'blur']
          }
        ],
        password_confirm: [
          {
            validator: validatePass,
            trigger: ['change', 'blur']
          }
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
      },
      loading: false,
      redirect: undefined,
    };
  },
  computed: {
    title() {
      return config.title;
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      apiGetCaptcha().then((resp) => {
        this.captchaSrc = resp.base64;
        this.form.hash = resp.hash;
      });
    },
    onRegister() {
      this.$refs.registerRefs.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        apiRegister(this.form).then(() => {
          this.$message.success("注册成功，请登录");
          this.type = "login";
          this.form = {
            account: "",
            password: "",
            password_confirm: "",
            code: "",
            hash: "",
          };
        })
          .catch(() => {
            this.getCaptcha();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    onLogin() {
      this.$refs.loginRefs.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$store
          .dispatch("actionLogin", this.form)
          .then(() => {
            this.$router.replace({path: this.redirect || "/"});
          })
          .catch(() => {
            this.getCaptcha();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  background-image: url("../../assets/images/login-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  .register-wrap {
    width: 400px;
    height: 400px;
    padding: 20px 0;
    box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    background: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    .login-title {
      font-size: 24px;
      //text-align: center;
      margin-bottom: 30px;
    }
  }

  .login-wrap {
    width: 400px;
    height: 320px;
    padding: 20px 0;
    box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    background: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    .login-title {
      font-size: 24px;
      //text-align: center;
      margin-bottom: 30px;
    }
  }

  .code {
    width: 33%;
    height: 30px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .code-image {
    width: 100%;
    height: 30px;
  }
}
</style>
